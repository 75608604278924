@tailwind base;
@tailwind components;
@tailwind utilities;
/*@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap&subset=latin,cyrillic");*/
@import "./buttons.css";
body {
    color: #000;
    font: Inter, sans-serif;
    min-width: 320px;
    overflow-x: hidden;
    @apply text-lg bg-gray-100;
}

body.dark {
    @apply bg-gray-800 text-white;
}

.content-main {
    max-width: 1280px;
    width: 100%;
    margin: 0 auto;
    min-height: calc(100svh - 124px);
    display: flex;
    flex-direction: column;
}

.content-main-padding {
    @apply px-4 md:px-6 pb-6;
}

.header {
    @apply flex gap-4 lg:gap-6 items-center p-4 md:px-6 md:py-8  top-0 bg-gray-100 dark:bg-gray-800 z-20;

    .header-btn {
        @apply text-lg font-medium gap-2 transition rounded-lg p-4 h-[40px] min-w-[40px] md:min-w-[60px] md:h-[60px] flex items-center justify-center relative;
    }

    .header-btn-left {
        @apply p-0 h-[40px] w-[40px] md:min-w-[60px] md:h-[60px];
    }

    .header-btn-right {
        @apply p-0 w-[40px] h-[40px] md:w-auto md:h-[60px] md:p-4;
    }
}

.header-content {
    @apply flex items-center flex-grow;
}

.header-title {
    @apply font-medium text-gray-900 dark:text-white text-lg md:text-2xl;
}

.tab-bar {
    @apply bg-gray-200 h-[40px] md:h-[60px] flex dark:bg-[#374151] flex-grow md:flex-grow-0 w-auto mx-auto self-center gap-1 rounded-lg p-1 text-lg font-medium;

    .tab-bar-item {
        @apply px-3 py-1 md:p-3 h-full relative;
        @apply text-sm md:text-lg font-medium cursor-pointer hover:bg-white gap-2.5 md:min-w-[200px] min-w-[auto] flex-grow flex items-center justify-center dark:hover:bg-dark transition rounded-lg text-center;
    }

    .tab-bar-item.active {
        @apply bg-white dark:bg-dark;
    }
}

.badge-circle {
    @apply w-[8px] h-[8px] rounded-full block;
}

.badge-info {
    @apply bg-orange-400 ring-orange-400;
}

.order-top-grid {
    grid-template-columns: calc(50% - 32px) 1fr;

    @media screen and (max-width: 1023px) {
        grid-template-columns: 1fr;
        justify-content: center;
        grid-template-areas: "status" "number" "user" "price" "actions";
    }
}

.order-info-title {
    @apply text-gray-300 dark:text-gray-500 text-sm relative flex items-center gap-2 pb-4;

    &::after {
        content: "";

        @apply bg-gray-300 dark:bg-gray-600 block flex-grow h-[1px];
    }
}

.input-base {
    min-height: 60px;
    @apply bg-gray-50  border ring-gray-300 border-gray-300  rounded-lg py-2 px-4 w-full  transition shadow-none outline-none;
    @apply dark:ring-gray-500 dark:border-gray-500 dark:bg-gray-700;
    @apply focus:outline-none focus:shadow-none focus:ring-2 focus:ring-gray-300;
}

.search-input {
    @apply bg-gray-50  border ring-gray-300 border-gray-300  rounded-lg py-2 px-4 min-w-full  transition shadow-none outline-none;
    @apply dark:ring-gray-500 dark:border-gray-500 dark:bg-gray-700;
    @apply focus:outline-none focus:shadow-none focus:ring-2 focus:ring-gray-300;
}

.input-base.invalid {
    @apply border-red-500 !important;
}

.basic-block {
    @apply bg-white dark:bg-gray-700;
}

.alter-block {
    @apply bg-white/40 dark:bg-gray-700/50;
}

.sidebar {
    max-width: 500px;
    transform: translateX(-100%);

    @apply basic-block flex flex-col top-0 left-0 bottom-0 fixed w-full h-full max-h-svh overflow-y-auto;

    @media screen and (max-width: 576px) {
        width: 100vw;
        height: 100vh;
    }
}

.sidebar-enter {
    transform: translateX(-100%);
}

.sidebar-enter-active,
.sidebar-enter-done {
    transform: translateX(0%);
    transition: transform 300ms ease-in-out;
}

.sidebar-exit {
    transform: translateX(0%);
}

.sidebar-exit-active,
.sidebar-exit-done {
    transform: translateX(-100%);
    transition: transform 300ms ease-in-out;
}

.react-datepicker {
    font-family: Inter, sans-serif !important;
    @apply w-full max-w-md;
    @apply rounded-lg text-base text-gray-900 dark:bg-gray-700 font-medium border-transparent shadow-md border-none outline-none !important;

    .react-datepicker__month-container {
        @apply w-full pb-2 border-none outline-none !important;
    }

    .react-datepicker__header {
        @apply bg-transparent border-none;
    }
    .react-datepicker__week{
        @apply w-full flex;
    }
    .react-datepicker__month {
        @apply p-0 m-0;
    }

    .react-datepicker__month-text--today,
    .react-datepicker__day--today {
        @apply hover:text-black dark:hover:text-white text-green-500 font-bold;
        @apply bg-transparent;
    }

    /*react-datepicker__month-text react-datepicker__month-2 react-datepicker__month-text--selected react-datepicker__month-text--keyboard-selected react-datepicker__month-text--today*/
    .react-datepicker__day-names{
        @apply flex;
    }
    .react-datepicker__day-name {
        @apply flex-1 inline-flex items-center justify-center p-2 m-0 text-gray-600 font-medium w-10 h-10 text-base  md:h-[40px] md:w-[60px] dark:text-white;
    }

    .react-datepicker__day,
    .react-datepicker__month-text {
        @apply flex-1;
        @apply dark:hover:text-black !important;
        @apply rounded-lg;
        @apply inline-flex items-center justify-center p-0 m-0 h-10 w-10 md:h-[40px] md:w-[60px] text-base dark:text-white  align-middle !important;
    }

    .react-datepicker__time-list-item {
        @apply flex items-center justify-center text-base py-2 h-auto font-medium !important;
    }

    .react-datepicker__time-list-item--selected,
    .react-datepicker__month-text--selected {
        @apply bg-gray-800 hover:bg-gray-600 !important;
    }

    .react-datepicker__day--selected,
    .react-datepicker__month-text--selected {
        @apply bg-gray-800 hover:bg-gray-600 rounded-lg text-white !important;
    }

    .react-datepicker__day--outside-month {
        @apply text-gray-400 dark:text-gray-400;
    }

    .react-datepicker__header--custom {
        @apply p-0 m-0 font-medium;
    }

    .picker-navigation {
        @apply bg-gray-200 transition hover:bg-gray-300 rounded-lg w-[40px] h-[40px] flex items-center justify-center cursor-pointer;
    }

    .react-datepicker__header--time {
        @apply py-4 !important;
    }

    .react-datepicker__input-time-container {
        @apply text-lg dark:text-white;
    }

    .react-datepicker__day--range-end,
    .react-datepicker__day--selecting-range-end.react-datepicker__day--in-range {
        @apply rounded-l-none rounded-r-lg !important;
        @apply bg-green-500 hover:bg-green-600 text-white !important;
    }

    .react-datepicker__day--range-start,
    .react-datepicker__day--selecting-range-start,
    .react-datepicker__day--selecting-range-start.react-datepicker__day--in-range {
        @apply rounded-r-none rounded-l-lg !important;
        @apply bg-green-500 hover:bg-green-600 text-white !important;
    }

    .react-datepicker__day--in-range:not(.react-datepicker__day--range-start),
    .react-datepicker__day--in-range:not(.react-datepicker__day--range-end),
    .react-datepicker__day--in-selecting-range:not(
      .react-datepicker__day--range-end
    ),
    .react-datepicker__day--in-selecting-range:not(
      .react-datepicker__day--range-start
    ) {
        @apply bg-gray-200 dark:bg-gray-600 text-gray-900 rounded-none;
        @apply hover:dark:text-white !important;
    }

    input.react-datepicker-time__input {
        @apply bg-gray-50 border dark:ring-gray-400 ring-gray-300 dark:bg-gray-500 focus:outline-none focus:shadow-none rounded-lg p-2 w-full  transition shadow-none outline-none focus:ring-2 focus:ring-gray-300;
    }
}

.sidebar-nav-item {
    @apply flex items-center gap-3 hover:text-orange-400 py-4 transition;

    .icon {
        @apply transition;
    }

    &:hover .icon {
        @apply text-orange-400 !important;
    }

    &.mobile {
        @apply flex md:hidden;
    }

    &.active {
        @apply text-orange-400;
    }
}

.with-border-color {
    @apply border-gray-100 dark:border-gray-600;
}

.with-divide-color {
    @apply divide-gray-100 dark:divide-gray-600;
}

.fade-enter {
    opacity: 0;

    td {
        border-color: transparent !important;
    }
}

.fade-enter-active,
.fade-enter-done {
    opacity: 1;
    transition: opacity 100ms ease-in;
}

.fade-exit {
    opacity: 1;

    td {
        border-color: transparent !important;
    }
}

.fade-exit-active,
.fade-exit-done {
    opacity: 0;
    transition: opacity 100ms ease-out;

    td {
        border-color: transparent !important;
    }
}

.text-muted {
    @apply text-gray-500 dark:text-gray-300;
}

.skeleton {
    animation: skeleton-animation 2s infinite;

    svg {
        opacity: 0;
    }

    @apply bg-gray-200 dark:bg-gray-400 rounded-xl min-h-[1em] text-transparent !important;
}

[data-skeleton="true"] {
    animation: skeleton-animation 2s infinite;
    @apply bg-gray-200 dark:bg-gray-400 rounded-xl min-h-[1em] text-transparent !important;
}

@keyframes skeleton-animation {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.5;
    }
    100% {
        opacity: 1;
    }
}
.dark .order-status{
    --end-color: #374151;
}
.order-status {
    --percent: 0%;
    --end-color: #fff;
    --start-color: currentColor;
    @apply text-green-500;

    background: conic-gradient(
            var(--start-color) var(--percent),
            var(--end-color) var(--percent),
            var(--end-color) 100%
    );
}

.order-status-inactive {
    --percent: 100%;
    @apply  text-gray-300 dark:text-gray-400 ;
}

.order-status-urgent {
    @apply text-orange-500;
}

.order-status-expired{
    @apply text-red-500;
}
.order-status-finished, .order-status-new{
    --percent: 100%;
    @apply text-gray-300  dark:text-gray-400 !important;
}

.toasts {
    .Toastify__toast--success {
        @apply bg-green-100 text-black font-medium;
    }

    .Toastify__toast--error {
        @apply bg-red-100 text-black font-medium;
    }

    .Toastify__close-button {
        @apply text-black opacity-100 hover:opacity-70;
    }
}

.iconic {
    @apply w-5 h-5 lg:w-6 lg:h-6;
}


.pkr {
    @apply grid grid-cols-[140px_60px_60px] gap-5 !important;
}

svg text {
    text-shadow: none !important;
}

text {
    text-shadow: none !important;
}

.apexcharts-text {
    text-shadow: none !important;
}

.if-grid {
    display: none;
}

.status-active, .status-released{
    background: #0E9F6E;
}
.status-inactive, .status-banned, .status-blocked{
    background: #F05252;
}
.status-paused, .status-pass{
    background: #9CA3AF;
}
.status-soon{
    background: #FACA15;
}
.status-hidden{
    background: #E5E7EB;
}

@supports (display: grid) {
    .if-grid {
        display: block;
    }
}