.btn {
  @apply rounded-lg text-lg cursor-pointer  transition;
}

.btn-info {
  @apply btn bg-gray-200 font-medium  dark:bg-gray-500 hover:bg-gray-300 dark:hover:bg-gray-400;

  &.active {
    @apply bg-green-500 hover:bg-green-500 dark:hover:bg-green-500 text-white;
  }

  &.disabled {
    @apply bg-gray-200 dark:bg-gray-600 opacity-100 dark:text-white/30 text-[#1119284D] !important;
  }
}

.btn-white {
  @apply btn bg-gray-900 dark:bg-white text-white dark:text-gray-900 font-medium  hover:bg-gray-600 dark:hover:bg-gray-400;
}

.btn-success {
  @apply btn bg-green-500 font-medium text-white dark:bg-green-400 hover:bg-green-600 dark:hover:bg-green-600/70;
}

.btn-loading,
button.disabled,
button[disabled] {
  @apply cursor-not-allowed pointer-events-none opacity-60;
}

.btn-outlined {
  @apply btn bg-transparent border border-gray-200  dark:border-gray-500 font-medium  hover:border-gray-300 dark:hover:border-gray-400;
}

.btn-danger {
  @apply btn bg-red-500 text-white dark:bg-red-400 hover:bg-red-600 dark:hover:bg-red-600/70;
}

@keyframes loadingF {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
